import { Divider, Grid, Stack, Typography } from '@mui/material';
import CPDatePicker from 'components/atoms/CPDatePicker';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import CPSwitch from 'components/atoms/CPSwitch';
import CPTextField from 'components/atoms/CPTextField';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { useTranslation } from 'react-i18next';
import styles from 'styles/Home.module.css';
import CampaignTable, { ICampaignTableHeader } from './CampaignTable';
import { useEffect, useState } from 'react';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { IUserAccountList } from 'types/userAccountList.type';
import { getReadableError } from 'utils/errorHelper';
import { useSnackbar } from 'notistack';
import CPAlert from 'components/atoms/CPAlert';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import { theme } from 'configs/theme';
import get from 'lodash/get';
import { getEndpointPromise, insertEndpointPromise } from 'services/apiServices';
//Icons
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CampaignSearchType from 'common/enums/campainSearchType';
import { IBaseTransaction, ICustomQuerySearchList } from 'types/customQuerySearchList';
import { ICustomQueryCreateBased } from 'types/customQuery.type';
import isNumber from 'lodash/isNumber';
import AddEditCustomizedQuery from '../AddEditCustomizedQuery';
import CustomQueryTableType from 'common/enums/customQueryTableType';
import isDate from 'lodash/isDate';

interface ICustomizedQuerySearchForm {
	endDate: Date | null;
	startDate: Date | null;
	accountId: string | null;
	searchType: string | null;
	campaign?: string | null;
	source?: string | null;
	medium?: string | null;
	sales: number | undefined;
	spends: number | undefined;
}

const CreateCustomizedQuery = () => {
	const { t } = useTranslation();
	const [clients, setClients] = useState<IOptionItem[] | []>([]);
	const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const [isLoadingMatch, setIsLoadingMatch] = useState<boolean>(false);
	const [isLoadingNotMatchGoogleAnalytics, setIsLoadingNotMatchGoogleAnalytics] = useState<boolean>(false);
	const [isLoadingNotMatchADPlatform, setIsLoadingNotMatchADPlatform] = useState<boolean>(false);
	const [matchList, setMatchList] = useState<IBaseTransaction>([]);
	const [notMatchGoogleList, setNotMatchGoogleList] = useState<IBaseTransaction>([]);
	const [notMatcheADPlatformList, setNotMatchADPlatformList] = useState<IBaseTransaction>([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isShowUnmapped, setShowUnmapped] = useState<boolean>(true);
	const [createCustomQuery, setCreateCustomQuery] = useState<ICustomQueryCreateBased | null>(null);
	const validationSchema = yup
		.object({
			accountId: yup.string().required(t('value_required')).trim().nullable(),
			campaign: yup
				.string()
				.max(255, t('cannot_exceed_255'))
				.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
				.trim()
				.nullable(),
			source: yup
				.string()
				.max(255, t('cannot_exceed_255'))
				.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
				.trim()
				.nullable(),
			medium: yup
				.string()
				.max(255, t('cannot_exceed_255'))
				.matches(/^("(.*\S.*)")|(\[(.*\S.*)\])$/, t('validation_within_brackets_or_commas'))
				.trim()
				.nullable(),
			searchType: yup.string().nullable(),
			sales: yup
				.number()
				.nullable()
				.test('maxDigitsAfterDecimal', t('invalid_number_format'), (number) =>
					/^(\s*|[0-9]{0,12}(\.[0-9]{0,2})?)$/.test(number?.toString() ?? '')
				),
			spends: yup
				.number()
				.nullable()
				.test('maxDigitsAfterDecimal', t('invalid_number_format'), (number) =>
					/^(\s*|[0-9]{0,12}(\.[0-9]{0,2})?)$/.test(number?.toString() ?? '')
				)
		})
		.shape({
			startDate: yup
				.date()
				.nullable()
				.typeError(t('invalid_date'))
				.required(t('value_required'))
				.transform((curr, orig) => (!isDate(orig) ? null : curr)),

			endDate: yup
				.date()
				.nullable()
				.typeError(t('invalid_date'))
				.transform((curr, orig) => (!isDate(orig) ? null : curr))
				.required(t('value_required'))
				.when('startDate', (startDate, schema) => {
					if (startDate !== null) {
						var value = (startDate as Date).getDate();
						if (!isNaN(value)) {
							return schema.min(startDate, t('end_date_cannot_be'));
						}
					}
				})
		});

	const searchForm = useFormik({
		initialValues: {
			startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
			accountId: null,
			endDate: new Date(),
			sales: undefined,
			searchType: null,
			spends: undefined,
			campaign: null,
			source: null,
			medium: null
		},
		validationSchema: validationSchema,
		onSubmit: (data: ICustomizedQuerySearchForm) => {
			let formData = JSON.parse(JSON.stringify(data)) as ICustomizedQuerySearchForm;
			if (formData.campaign === '') {
				formData.campaign = null;
			}
			if (formData.source === '') {
				formData.source = null;
			}
			if (formData.medium === '') {
				formData.medium = null;
			}
			if (isShowUnmapped) {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.UNMAPPED_MATCH;
				getSearchResult(searchData);
			} else {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.MAPPED_MATCH;
				getSearchResult(searchData);
			}

			if (isShowUnmapped) {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.UNMAPPED_UNMATCH_GOOGLEANALYTICS;
				getSearchResult(searchData);
			} else {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.MAPPED_UNMATCH_GOOGLEANALYTICS;
				getSearchResult(searchData);
			}

			if (isShowUnmapped) {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.UNMAPPED_UNMATCH_ADPLATFORM;
				getSearchResult(searchData);
			} else {
				let searchData = JSON.parse(JSON.stringify(formData)) as ICustomizedQuerySearchForm;
				searchData.searchType = CampaignSearchType.MAPPED_UNMATCH_ADPLATFORM;
				getSearchResult(searchData);
			}
		}
	});

	const matchedCampaignName: ICampaignTableHeader = {
		groups: [
			{
				colSpan: 2,
				id: 'google_analytics',
				label: t('google_analytics')
			},
			{
				colSpan: 4,
				id: 'ad_platform',
				label: t('ad_platform')
			}
		],
		columns: [
			{
				id: 'campaign',
				label: t('campaign'),
				align: 'left',
				minWidth: '',
				leftConditionFalseIcon: (
					<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
				),
				leftConditionTrueIcon: <div style={{ marginRight: '36px' }} />,
				leftIconCondition: 'isMappedGA'
			},
			{
				id: 'googleAnalyticsSales',
				label: t('sales'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			},
			{
				id: 'campaign',
				label: t('campaign'),
				align: 'left',
				minWidth: '',
				leftConditionFalseIcon: (
					<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
				),
				leftConditionTrueIcon: <div style={{ marginRight: '36px' }} />,
				leftIconCondition: 'isMappedAD'
			},
			{
				id: 'adPlatformSales',
				label: t('sales'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			},
			{
				id: 'adPlatformSpends',
				label: t('spends'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			}
		]
	};
	const notMatchedGoogleCampaignName: ICampaignTableHeader = {
		groups: [
			{
				colSpan: 5,
				id: 'google_analytics',
				label: t('google_analytics')
			}
		],
		columns: [
			{
				id: 'source',
				label: t('source'),
				align: 'left',
				minWidth: '',
				leftConditionFalseIcon: (
					<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
				),
				leftConditionTrueIcon: <div style={{ marginRight: '36px' }} />,
				leftIconCondition: 'isMappedGA'
			},
			{
				id: 'medium',
				label: t('medium'),
				align: 'left',
				minWidth: ''
			},
			{
				id: 'campaign',
				label: t('campaign'),
				align: 'left',
				minWidth: ''
			},
			{
				id: 'googleAnalyticsSales',
				label: t('sales'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			}
		]
	};
	const notMatchedADPlatformCampaignName: ICampaignTableHeader = {
		groups: [
			{
				colSpan: 4,
				id: 'ad_platform',
				label: t('ad_platform')
			}
		],
		columns: [
			{
				id: 'campaign',
				label: t('campaign'),
				align: 'left',
				minWidth: '',
				leftConditionFalseIcon: (
					<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
				),
				leftConditionTrueIcon: <div style={{ marginRight: '36px' }} />,
				leftIconCondition: 'isMappedAD'
			},
			{
				id: 'adPlatformSales',
				label: t('sales'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			},
			{
				id: 'adPlatformSpends',
				label: t('spends'),
				align: 'left',
				minWidth: '',
				type: 'currency'
			}
		]
	};
	// useEffects
	useEffect(() => {
		getClients();
	}, []);

	// functions
	const updateDefaultValues = () => {
		if (!isNumber(searchForm.values.spends)) {
			searchForm.setFieldValue('spends', undefined);
		}
		if (!isNumber(searchForm.values.sales)) {
			searchForm.setFieldValue('sales', undefined);
		}
	};
	const getClients = async () => {
		try {
			setIsLoadingClientList(true);
			const response = await getEndpointPromise<IUserAccountList>(`/entitymanager/client-account/list`);
			var clients: IOptionItem[] = [];
			response.data.userAccountList?.forEach((value: any) => {
				clients.push({ value: value.id, label: value.clientName });
			});
			let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingClientList(false);
		}
	};
	const getSearchResult = async (data: ICustomizedQuerySearchForm) => {
		if (data.searchType === CampaignSearchType.MAPPED_MATCH || data.searchType === CampaignSearchType.UNMAPPED_MATCH) {
			setIsLoadingMatch(true);
		} else if (
			data.searchType === CampaignSearchType.MAPPED_UNMATCH_GOOGLEANALYTICS ||
			data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_GOOGLEANALYTICS
		) {
			setIsLoadingNotMatchGoogleAnalytics(true);
		} else if (
			data.searchType === CampaignSearchType.MAPPED_UNMATCH_ADPLATFORM ||
			data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_ADPLATFORM
		) {
			setIsLoadingNotMatchADPlatform(true);
		}

		try {
			const response = await insertEndpointPromise<ICustomQuerySearchList>(
				`entitymanager/base-transaction/search`,
				data
			);
			if (
				data.searchType === CampaignSearchType.MAPPED_MATCH ||
				data.searchType === CampaignSearchType.UNMAPPED_MATCH
			) {
				setMatchList(get(response, 'data.baseTransactionList', []));
			} else if (
				data.searchType === CampaignSearchType.MAPPED_UNMATCH_GOOGLEANALYTICS ||
				data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_GOOGLEANALYTICS
			) {
				setNotMatchGoogleList(get(response, 'data.baseTransactionList', []));
			} else if (
				data.searchType === CampaignSearchType.MAPPED_UNMATCH_ADPLATFORM ||
				data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_ADPLATFORM
			) {
				setNotMatchADPlatformList(get(response, 'data.baseTransactionList', []));
			}
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			if (
				data.searchType === CampaignSearchType.MAPPED_MATCH ||
				data.searchType === CampaignSearchType.UNMAPPED_MATCH
			) {
				setIsLoadingMatch(false);
			} else if (
				data.searchType === CampaignSearchType.MAPPED_UNMATCH_GOOGLEANALYTICS ||
				data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_GOOGLEANALYTICS
			) {
				setIsLoadingNotMatchGoogleAnalytics(false);
			} else if (
				data.searchType === CampaignSearchType.MAPPED_UNMATCH_ADPLATFORM ||
				data.searchType === CampaignSearchType.UNMAPPED_UNMATCH_ADPLATFORM
			) {
				setIsLoadingNotMatchADPlatform(false);
			}
		}
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('customized_queries')} />
			<main className={styles.main}>
				<div style={{ width: '100%' }}>
					<div style={{ marginTop: '24px', marginBottom: '40px' }}>
						{/* Search tools */}
						<Typography variant="header1">{t('search')}</Typography>
						<div className={styles.groupItems} style={{ marginTop: '12px' }}>
							<form style={{ padding: '24px 28px' }}>
								<Typography variant="info1">{t('custom_query_search_info1')}</Typography>
								<Grid container rowSpacing={2} columnSpacing={4} sx={{ paddingTop: '16px' }}>
									<Grid item xs={12} sm={6} md={4}>
										<CPSingleSelectAutoCompleteDropDown
											name="accountId"
											size="small"
											options={clients}
											label={t('clientName')}
											onBlur={searchForm.handleBlur}
											setFieldValue={searchForm.setFieldValue}
											loading={isLoadingClientList}
											disableClearable
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<CPDatePicker
											name="startDate"
											label={t('start_date')}
											handleChange={(date) => {
												searchForm.setFieldTouched('startDate', true);
												searchForm.setFieldValue('startDate', date);
											}}
											size="small"
											fullWidth
											value={searchForm.values.startDate}
											onBlur={searchForm.handleBlur}
											error={searchForm.errors.startDate ? true : false}
											helperText={searchForm.errors.startDate}
											maxDate={searchForm.values.endDate}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<CPDatePicker
											name="endDate"
											label={t('end_date')}
											handleChange={(date) => {
												searchForm.setFieldTouched('endDate', true);
												searchForm.setFieldValue('endDate', date);
											}}
											size="small"
											fullWidth
											value={searchForm.values.endDate}
											onBlur={searchForm.handleBlur}
											error={searchForm.errors.endDate ? true : false}
											helperText={searchForm.errors.endDate}
											maxDate={new Date()}
											minDate={searchForm.values.startDate}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} sx={{ display: 'flex' }}>
										<CPSwitch handleChange={(_, isChecked) => setShowUnmapped(isChecked)} checked={isShowUnmapped} />
										<Typography variant="body2" sx={{ marginLeft: '8px', alignSelf: 'center' }}>
											{t('show_only_unmapped')}
										</Typography>
									</Grid>
								</Grid>
								<div style={{ display: 'flex', marginTop: '28px' }}>
									<Typography variant="info1" sx={{ flex: 'none', paddingRight: '28px' }}>
										{t('advanced_search')}
									</Typography>
									<div style={{ alignSelf: 'center', width: '100%' }}>
										<Divider />
									</div>
								</div>
								<div style={{ marginTop: '16px' }}>
									<Typography variant="info1">{t('custom_query_search_info2')}</Typography>
									<Grid container sx={{ paddingTop: '16px' }} rowSpacing={2} columnSpacing={4}>
										<Grid item xs={12} sm={6} md={4}>
											<CPTextField
												label={t('source')}
												size="small"
												fullWidth
												name="source"
												onBlur={searchForm.handleBlur}
												handleChange={searchForm.handleChange}
												error={searchForm.touched.source && searchForm.errors.source ? true : false}
												helperText={searchForm.touched.source ? searchForm.errors.source : ''}
												value={searchForm.values.source ?? ''}
											/>
										</Grid>
										{/* new */}
										<Grid item xs={12} sm={6} md={4}>
											<CPTextField
												label={t('medium')}
												size="small"
												fullWidth
												name="medium"
												onBlur={searchForm.handleBlur}
												handleChange={searchForm.handleChange}
												error={searchForm.touched.medium && searchForm.errors.medium ? true : false}
												helperText={searchForm.touched.medium ? searchForm.errors.medium : ''}
												value={searchForm.values.medium ?? ''}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<CPTextField
												label={t('campaign')}
												size="small"
												fullWidth
												name="campaign"
												onBlur={searchForm.handleBlur}
												handleChange={searchForm.handleChange}
												error={searchForm.touched.campaign && searchForm.errors.campaign ? true : false}
												helperText={searchForm.touched.campaign ? searchForm.errors.campaign : ''}
												value={searchForm.values.campaign ?? ''}
											/>
										</Grid>
										{/*  */}

										<Grid item xs={12} sm={6} md={4}>
											<CPTextField
												label={t('sales')}
												size="small"
												fullWidth
												name="sales"
												type="number"
												InputProps={{ inputProps: { min: 0, inputMode: 'numeric' } }}
												onBlur={searchForm.handleBlur}
												handleChange={searchForm.handleChange}
												value={searchForm.values.sales}
												error={searchForm.touched.sales && searchForm.errors.sales ? true : false}
												helperText={searchForm.touched.sales ? searchForm.errors.sales : ''}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<CPTextField
												label={t('spends')}
												size="small"
												fullWidth
												name="spends"
												type="number"
												InputProps={{ inputProps: { min: 0, inputMode: 'numeric' } }}
												onBlur={searchForm.handleBlur}
												handleChange={searchForm.handleChange}
												value={searchForm.values.spends}
												error={searchForm.touched.spends && searchForm.errors.spends ? true : false}
												helperText={searchForm.touched.spends ? searchForm.errors.spends : ''}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<CPLoadingButton
												label={
													<>
														<ManageSearchIcon sx={{ marginRight: '12px' }} />
														{t('search')}
													</>
												}
												variant={'contained'}
												style={{ width: '100%', borderRadius: '32px' }}
												loading={isLoadingClientList}
												onClick={() => {
													updateDefaultValues();
													searchForm.submitForm();
												}}
												disabled={!(searchForm.isValid && searchForm.dirty)}
											/>
										</Grid>
									</Grid>
								</div>
							</form>
						</div>
						{/* Search tools */}
						{/* Campaign Names Matches */}
						<div style={{ marginTop: '50px' }}>
							<Stack direction="row" justifyContent="space-between" alignItems="baseline">
								<Typography variant="header1">{t('campaign_names_match')}</Typography>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
									<Typography variant="body2">{t('unmapped_queries')}</Typography>
								</div>
							</Stack>
							<div style={{ marginTop: '24px' }}>
								<CampaignTable
									tableHeader={matchedCampaignName}
									loading={isLoadingMatch}
									rows={matchList as any[]}
									onCreateAction={(data: any) => {
										let client: IOptionItem = clients.find(
											(element) => element.value === searchForm.values.accountId
										) as IOptionItem;

										var createData: ICustomQueryCreateBased = {
											client: client,
											campaign: get(data, 'campaign', ''),
											fromTable: CustomQueryTableType.GOOGLEANALYTICS_AND_ADPLATFORM,
											source: get(data, 'source', ''),
											medium: get(data, 'medium', '')
										};
										setCreateCustomQuery(createData);
									}}
								/>
							</div>
						</div>
						{/* Campaign names don’t match */}
						<div style={{ marginTop: '50px' }}>
							<Stack direction="row" justifyContent="space-between" alignItems="baseline">
								<Typography variant="header1">{t('campaign_names_do_not_match')}</Typography>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<LinkOffIcon fontSize="small" sx={{ color: theme.palette.primary.linkOff, marginRight: '16px' }} />
									<Typography variant="body2">{t('unmapped_queries')}</Typography>
								</div>
							</Stack>

							{/* Google */}
							<div style={{ marginTop: '24px' }}>
								<CampaignTable
									tableHeader={notMatchedGoogleCampaignName}
									loading={isLoadingNotMatchGoogleAnalytics}
									rows={notMatchGoogleList as any[]}
									onCreateAction={(data: any) => {
										let client: IOptionItem = clients.find(
											(element) => element.value === searchForm.values.accountId
										) as IOptionItem;

										var createData: ICustomQueryCreateBased = {
											client: client,
											campaign: get(data, 'campaign', ''),
											fromTable: CustomQueryTableType.GOOGLEANALYTICS,
											source: get(data, 'source', ''),
											medium: get(data, 'medium', '')
										};
										setCreateCustomQuery(createData);
									}}
								/>
							</div>
							{/* AD Platform */}
							<div style={{ marginTop: '40px' }}>
								<CampaignTable
									tableHeader={notMatchedADPlatformCampaignName}
									loading={isLoadingNotMatchADPlatform}
									rows={notMatcheADPlatformList as any[]}
									onCreateAction={(data: any) => {
										let client: IOptionItem = clients.find(
											(element) => element.value === searchForm.values.accountId
										) as IOptionItem;
										var createData: ICustomQueryCreateBased = {
											client: client,
											campaign: get(data, 'campaign', ''),
											fromTable: CustomQueryTableType.ADPLATFORM,
											source: get(data, 'source', ''),
											medium: get(data, 'medium', '')
										};
										setCreateCustomQuery(createData);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<AddEditCustomizedQuery
					open={createCustomQuery ? true : false}
					handleClose={() => {
						setCreateCustomQuery(null);
					}}
					getAllCustomQueries={() => {
						updateDefaultValues();
						searchForm.submitForm();
					}}
					createCustomQuery={createCustomQuery}
				/>
			</main>
		</div>
	);
};

export default CreateCustomizedQuery;
