import { CircularProgress, Typography } from '@mui/material';
import ComparisonLabelTable from 'components/molecules/ComparisonLabelTable';
import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import LabelTable from 'components/molecules/LabelTable';
import { useTranslation } from 'react-i18next';

const DeltaLabelReportListContainer = (props: any) => {
	const { labelList, labelReportList, searchLabelReportLoading } = props;
	const { t } = useTranslation();

	const labelColumns: IColumn[] = [
		{ id: 'funnelName', label: '', minWidth: '382px', align: 'left', style: { borderLeft: 0 } },
		{
			id: 'cost',
			label: t('investment'),
			minWidth: '288px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'spAttributeRevenue',
			label: t('cp_after_sales'),
			minWidth: '144px',
			align: 'right',
			type: 'currency',
			style: { borderLeft: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'spAttributeRoas',
			label: t('cp_after_roas'),
			minWidth: '138px',
			align: 'right',
			style: { borderRight: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'gaRevenue',
			label: t('ga_revenue'),
			minWidth: '144px',
			align: 'right',
			type: 'currency',
			style: { borderLeft: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'gaRoas',
			label: t('ga_roas'),
			minWidth: '138px',
			align: 'right',
			style: { borderRight: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'platformRevenue',
			label: t('platform_revenue'),
			minWidth: '144px',
			align: 'right',
			type: 'currency',
			style: { borderLeft: 10, borderColor: '#E5E5E5' }
		},
		{
			id: 'platformRoas',
			label: t('platform_roas'),
			minWidth: '138px',
			align: 'right',
			style: { borderRight: 0 }
		}
	];

	const comparisonTableColumns: IColumn[] = [
		{
			id: 'spendChange',
			label: t('spend_change'),
			minWidth: '144px',
			align: 'left',
			colSpan: 2
		},
		{
			id: 'revenueChange',
			label: t('revenue_change'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},

		{
			id: 'spendChangePercentage',
			label: t('spend_change_percentage'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},
		{
			id: 'revChangePercentage',
			label: t('rev_change_percentage'),
			minWidth: '144px',
			align: 'right',
			colSpan: 2
		},
		{
			id: 'changePercentage',
			label: t('change_percentage'),
			minWidth: '144px',
			align: 'right'
		}
	];

	const getTableData = (tableDataList: any) => {
		let table1: any = [];
		let table2: any = [];
		let comparision: any = [];
		tableDataList.forEach((value: any) => {
			table1.push({
				funnelName: value.funnelName,
				...value.tableOneData
			});
			table2.push({
				...value.tableTwoData
			});
			comparision.push({
				...value.comparisonData
			});
		});
		return { table1: table1, table2: table2, comparision: comparision };
	};

	return (
		<>
			{searchLabelReportLoading ? (
				<>
					<Typography align="center">
						<CircularProgress />
						<br />
						{t('loading_labels_reports')}
					</Typography>
				</>
			) : (
				labelList.map((label: number) =>
					labelReportList.map((labelReport: any) => {
						let tempObj = getTableData(labelReport.data.tableDataList);
						return (
							labelReport.data.labelId === label && (
								<div
									key={labelReport.data.labelName}
									style={{ marginBottom: '20px', display: 'flex', overflow: 'auto' }}
								>
									<div style={{ margin: '10px 50px 10px 0px' }}>
										<LabelTable
											columns={labelColumns}
											loading={searchLabelReportLoading}
											labelName={labelReport.data.labelName}
											rows={tempObj.table1}
											totalRow={labelReport.data.tableOneTotal}
										/>
									</div>
									<div style={{ margin: '10px 50px 10px 0px' }}>
										<LabelTable
											hideLabel={true}
											columns={labelColumns}
											loading={searchLabelReportLoading}
											labelName={labelReport.data.labelName}
											rows={tempObj.table2}
											totalRow={labelReport.data.tableTwoTotal}
										/>
									</div>
									<div style={{ margin: '10px 50px 10px 0px' }}>
										<ComparisonLabelTable
											columns={comparisonTableColumns}
											totalRow={labelReport.data.comparisonTotal}
											rows={tempObj.comparision}
											loading={searchLabelReportLoading}
										/>
									</div>
								</div>
							)
						);
					})
				)
			)}
		</>
	);
};

export default DeltaLabelReportListContainer;
