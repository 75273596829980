import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
	useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from 'components/atoms/Loader';
import { formatCurrency } from 'utils/commonUtil';
import { ReportTableCell, TotalReportTableCell } from 'components/atoms/CPReportTableCell';

const ComparisonLabelTable = (props: any) => {
	const theme = useTheme();
	const { columns, rows, totalRow, loading, headerColor = theme.palette.primary.labelReport.headerColor } = props;
	const { t } = useTranslation();

	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: '750px'
				}
			}}
		>
			<Table stickyHeader aria-label="a dense table" sx={{ tableLayout: 'unset' }}>
				<TableHead sx={{ background: headerColor, color: theme.palette.primary.labelReport.fontColor }}>
					<TableRow sx={{ height: '10px' }}>
						<TotalReportTableCell>{t('spend_change')}</TotalReportTableCell>
						<TotalReportTableCell>{t('revenue_change')}</TotalReportTableCell>
						<TotalReportTableCell>{t('spend_hange_percentage')}</TotalReportTableCell>
						<TotalReportTableCell>{t('rev_change_percentage')}</TotalReportTableCell>
						<TotalReportTableCell>{t('Change_percentage')}</TotalReportTableCell>
					</TableRow>
				</TableHead>
				<TableBody sx={{ marginBottom: 10 }}>
					{rows.length === 0 && !loading && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#363636' }} align="center">
									{'No Records'}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{loading && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{!loading &&
						rows.map((row: any, index: number) => (
							<TableRow key={index}>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.spendChange ? row.spendChange : 0)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.revenueChange ? row.revenueChange : 0)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.spendChangePercentage}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.revChangePercentage}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.changePercentage}
								</ReportTableCell>
							</TableRow>
						))}
				</TableBody>
				{!loading && (
					<TableFooter sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}>
						<TableRow>
							<TotalReportTableCell
								sx={{
									borderRight: 10,
									borderColor: '#E5E5E5'
								}}
							>
								{formatCurrency(totalRow.spendChange ? totalRow.spendChange : 0)}
							</TotalReportTableCell>
							<TotalReportTableCell>
								{formatCurrency(totalRow.revenueChange ? totalRow.revenueChange : 0)}
							</TotalReportTableCell>
							<TotalReportTableCell
								sx={{
									borderRight: 10,
									borderColor: '#E5E5E5'
								}}
							>
								{totalRow.spendChangePercentage}
							</TotalReportTableCell>

							<TotalReportTableCell
								sx={{
									borderLeft: 10,
									borderColor: '#E5E5E5'
								}}
							>
								{totalRow.revChangePercentage}
							</TotalReportTableCell>
							<TotalReportTableCell
								sx={{
									borderRight: 10,
									borderColor: '#E5E5E5'
								}}
							>
								{totalRow.changePercentage}
							</TotalReportTableCell>
						</TableRow>
					</TableFooter>
				)}
			</Table>
		</TableContainer>
	);
};

export default ComparisonLabelTable;
