import ACCESS_TAGS from 'common/constants/accessTags';
import Loader from 'components/atoms/Loader';
import _ from 'lodash';
import CustomizedQueries from 'pages/CustomizedQueries';
import CreateCustomizedQuery from 'pages/CustomizedQueries/CreateCustomizedQuery';
import ChannelReport from 'pages/DailyStatistics/ChannelsReport';
import FunnelReport from 'pages/DailyStatistics/FunnelReport';
import MyProfile from 'pages/ProfileManagement/MyProfile';
import DeltaAnalysis from 'pages/Statistics/DeltaAnalysis';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { RootState } from 'store';

const Dashboard = lazy(() => import('pages/Dashboard'));
const NotFound = lazy(() => import('pages/NotFound'));
const Accounts = lazy(() => import('pages/UserManagement/Accounts'));
const Clients = lazy(() => import('pages/UserManagement/Clients'));
const GlobalQueryList = lazy(() => import('pages/GlobalQueryList'));
const Channels = lazy(() => import('pages/Channels'));
const CPAttributionEntry = lazy(() => import('pages/Statistics/CPAttributionEntry'));
const SingleAnalysis = lazy(() => import('pages/Statistics/SingleAnalysis'));

const ROUTES = [
	{
		path: '/channels',
		component: <Channels />,
		auth: 'GET_ALL_CHANNELS'
	},
	{
		path: '/user-management/accounts',
		component: <Accounts />,
		auth: ACCESS_TAGS.GET_ALL_USERS
	},
	{
		path: '/user-management/clients',
		component: <Clients />,
		auth: ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT
	},
	{
		path: '/',
		component: <Dashboard />
	},
	{
		path: '/global-query-list',
		component: <GlobalQueryList />,
		auth: ACCESS_TAGS.GET_GLOBAL_QUERY
	},
	{
		path: '/customized-queries',
		component: <CustomizedQueries />,
		auth: ACCESS_TAGS.GET_CUSTOM_QUERY
	},
	{
		path: '/my-profile',
		component: <MyProfile />
	},
	{
		path: '/customized-queries/create-customized-query',
		component: <CreateCustomizedQuery />,
		auth: ACCESS_TAGS.CREATE_CUSTOM_QUERY
	},
	{
		path: '/statistics/sp-attribution-entry',
		component: <CPAttributionEntry />,
		auth: ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
	},
	{
		path: '/statistics/single-analysis',
		component: <SingleAnalysis />,
		auth: ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT
	},
	{
		path: '/statistics/delta-analysis',
		component: <DeltaAnalysis />,
		auth: ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT
	},
	{
		path: '/daily-report/funnel-report',
		component: <FunnelReport />,
		auth: ACCESS_TAGS.DAILY_FUNNEL_REPORT
	},
	{
		path: '/daily-report/channel-report',
		component: <ChannelReport />,
		auth: ACCESS_TAGS.DAILY_CHANNEL_REPORT
	}
];

const AppRoutes = () => {
	const userAccess = useSelector((state: RootState) => state.userProfile);

	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				{ROUTES.map(
					(route, index) =>
						(_.get(userAccess, 'userProfile.featureList', []).includes(route.auth) || !route.auth) && (
							<Route key={index} element={route.component} path={route.path} />
						)
				)}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	);
};

export default AppRoutes;
