import styles from 'styles/Home.module.css';
import Breadcrumb from 'components/molecules/Breadcrumb';
import { Divider, Grid, Typography } from '@mui/material';
import CPDatePicker from 'components/atoms/CPDatePicker';
import { useTranslation } from 'react-i18next';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useEffect, useState } from 'react';
import { getEndpointPromise, insertEndpointPromise } from 'services/apiServices';
import CPAlert from 'components/atoms/CPAlert';
import { getReadableError } from 'utils/errorHelper';
import { IOptionItem } from 'interfaces/optionItem.interface';
import { ILabels } from 'types/label.type';
import { useSnackbar } from 'notistack';
import CPSingleSelectAutoCompleteDropDown from 'components/atoms/CPSingleSelectAutoCompleteDropDown';
import CPLoadingButton from 'components/atoms/CPLoadingButton';
import _, { get } from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';
import FunnelReportContainer from "./FunnelReportListContainer'";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getDownloadFileName, isAuthOperation } from 'utils/commonUtil';
import fileDownload from 'js-file-download';
import ACCESS_TAGS from 'common/constants/accessTags';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const FunnelReport = () => {
	const { t } = useTranslation();
	const [clients, setClients] = useState<IOptionItem[] | []>([]);
	const [isLoadingClientList, setIsLoadingClientList] = useState<boolean>(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [rows, setRows] = useState<any>([]);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	const [isExportingReport, setIsExportingReport] = useState<boolean>(false);
	const userAccess = useSelector((state: RootState) => state.userProfile);

	const validationSchema = yup
		.object({
			clientId: yup.string().required(t('value_required')).trim().nullable()
		})
		.shape({
			startDate: yup
				.date()
				.default(() => new Date())
				.required(t('value_required')),
			endDate: yup
				.date()
				.when('startDate', (startDate, schema) => startDate && schema.min(startDate, t('end_date_cannot_be')))
				.required(t('value_required'))
		});

	const searchForm = useFormik({
		initialValues: {
			startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
			endDate: new Date(),
			clientId: null
		},
		validationSchema: validationSchema,
		onSubmit: () => {
			handleFunnelReportSearch();
		}
	});

	const handleFunnelReportSearch = async () => {
		try {
			setSearchLoading(true);
			let funnelData = await insertEndpointPromise(`/entitymanager/analysis-report/daily-funnel-report`, {
				clientId: searchForm.values.clientId,
				startDate: searchForm.values.startDate,
				endDate: searchForm.values.endDate
			});

			setRows(funnelData.data.funnelReport);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setSearchLoading(false);
		}
	};

	useEffect(() => {
		getClients();
	}, []);

	const getClients = async () => {
		if (!isAuthOperation(get(userAccess, 'userProfile.featureList', []), ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA)) {
			let clientId = get(userAccess, 'userProfile.clientId');
			let clientName = get(userAccess, 'userProfile.clientName');
			var client: IOptionItem = { value: clientId, label: clientName };
			setClients([client]);
			searchForm.setFieldValue('clientId', clientId);
			return;
		}
		try {
			setIsLoadingClientList(true);
			const response = await getEndpointPromise<ILabels>(`/entitymanager/client-account/list`);
			var clients: IOptionItem[] = [];
			response.data.userAccountList?.forEach((value: any) => {
				clients.push({ value: value.id, label: value.clientName });
			});
			let sortedClients = clients.sort((a, b) => Number(a.label.toLowerCase() > b.label.toLowerCase()));
			setClients(sortedClients);
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsLoadingClientList(false);
		}
	};

	const handleDataExport = async () => {
		try {
			setIsExportingReport(true);
			let response = await insertEndpointPromise(`/entitymanager/analysis-report/download-daily-funnel-report`, {
				clientId: searchForm.values.clientId,
				startDate: searchForm.values.startDate,
				endDate: searchForm.values.endDate
			});
			let disposition = response.headers['content-disposition'];
			let filename = getDownloadFileName(disposition);
			fileDownload(response.data, filename || 'FunnelsReport.csv');
		} catch (error) {
			let message = getReadableError(error);
			const key = enqueueSnackbar(
				<CPAlert title={t('error')} message={message} severity={'error'} onClose={() => closeSnackbar(key)} />
			);
		} finally {
			setIsExportingReport(false);
		}
	};

	const getPageCSS = () => {
		return `@page { margin: 15px  15px  15px  15px !important; background-color: #FFF;size: landscape; }`;
	};

	return (
		<div className={styles.container}>
			<Breadcrumb title={t('funnel_report')} />
			<main className={styles.main}>
				<div style={{ width: '100%', backgroundColor: '#FFF' }} id="funnel-report">
					<style>{getPageCSS()}</style>
					<Typography variant="h6" sx={{ marginTop: '10px' }}>
						{t('Search')}
					</Typography>
					<div className={styles.groupItems} style={{ marginTop: '12px', marginBottom: '12px' }}>
						<div style={{ padding: '10px 20px' }}>
							<Grid container spacing={1} sx={{ padding: '10px 0px 24px 0px' }}>
								<Grid item md={12} xs={12} sx={{ paddingTop: 0, marginBottom: '15px' }}>
									<Typography variant="info1" sx={{ fontSize: '11px', fontWeight: 400, lineHeight: '16.5px' }}>
										{t('need_to_select_client_to_start_the_search')}
									</Typography>
								</Grid>
								<Grid item md={3} xs={12}>
									<CPSingleSelectAutoCompleteDropDown
										name="clientId"
										size="small"
										options={clients}
										label={t('client')}
										loading={isLoadingClientList}
										disableClearable
										onBlur={searchForm.handleBlur}
										setFieldValue={searchForm.setFieldValue}
										error={searchForm.touched.clientId && searchForm.errors.clientId ? true : false}
										helperText={searchForm.touched.clientId ? searchForm.errors.clientId : ''}
										disabled={
											!isAuthOperation(
												get(userAccess, 'userProfile.featureList', []),
												ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
											)
										}
										value={get(
											[...clients].filter((e) => e.value === searchForm.values.clientId),
											'0',
											null
										)}
									/>
								</Grid>
								<Grid item md={1} xs={1} justifyContent="center" alignItems="center" display="flex">
									<Divider orientation="vertical" style={{ height: '100%', width: '1px' }} />
								</Grid>
								<Grid item md={3} xs={12}>
									<CPDatePicker
										name="startDate"
										label={t('start_date')}
										handleChange={(date) => {
											searchForm.setFieldValue('startDate', date);
										}}
										size="small"
										fullWidth
										value={searchForm.values.startDate}
										onBlur={searchForm.handleBlur}
										error={searchForm.touched.startDate && searchForm.errors.startDate ? true : false}
										helperText={searchForm.touched.startDate ? searchForm.errors.startDate : ''}
										maxDate={searchForm.values.endDate}
									/>
								</Grid>
								<Grid item md={3} xs={12}>
									<CPDatePicker
										name="endDate"
										label={t('end_date')}
										handleChange={(date) => {
											searchForm.setFieldValue('endDate', date);
										}}
										size="small"
										fullWidth
										minDate={searchForm.values.startDate}
										value={searchForm.values.endDate}
										onBlur={searchForm.handleBlur}
										error={searchForm.touched.endDate && searchForm.errors.endDate ? true : false}
										helperText={searchForm.touched.endDate ? searchForm.errors.endDate : ''}
									/>
								</Grid>

								<Grid item md={2} xs={12}>
									<CPLoadingButton
										label={
											<>
												<ManageSearchIcon sx={{ marginRight: '12px' }} />
												{t('search')}
											</>
										}
										variant={'contained'}
										style={{ width: '100%', borderRadius: '32px' }}
										loading={isLoadingClientList || searchLoading}
										onClick={searchForm.submitForm}
										disabled={!(searchForm.isValid && searchForm.dirty)}
									/>
								</Grid>
							</Grid>
						</div>
					</div>
					<Grid container spacing={2} sx={{ marginBottom: '15px' }}>
						<Grid item xs={6}>
							<Typography variant="h6" sx={{ marginTop: '15px' }}>
								{t('funnel_report')}
							</Typography>
						</Grid>
						<Grid item xs={6} justifyContent={'flex-end'} display="flex">
							<span style={{ padding: '20px' }}></span>
							<CPLoadingButton
								label={t('export')}
								startIcon={<FileUploadIcon />}
								onClick={handleDataExport}
								variant="contained"
								loading={isExportingReport}
								disabled={!(searchForm.isValid && searchForm.dirty && !searchLoading)}
							/>
						</Grid>
					</Grid>

					<div id="FunnelReportContainer" style={{ backgroundColor: '#FFF' }}>
						<div id="client-name-div"></div>
						<div id="date-period-div"></div>

						<FunnelReportContainer FunnelReportDataList={rows} searchFunnnelReportLoading={searchLoading} />
					</div>
				</div>
			</main>
		</div>
	);
};

export default FunnelReport;
