import ACCESS_TAGS from 'common/constants/accessTags';

export const APP_NAVIGATION = [
	{
		id: 'user-management',
		label: 'user_management',
		icon: 'group',
		auth: [ACCESS_TAGS.GET_ALL_USERS, ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT],
		children: [
			{
				id: 'user-management-accounts',
				label: 'accounts',
				icon: 'remove',
				auth: [ACCESS_TAGS.GET_ALL_USERS],
				path: '/user-management/accounts'
			},
			{
				id: 'user-management-clients',
				label: 'clients',
				icon: 'remove',
				auth: [ACCESS_TAGS.GET_ALL_CLIENT_ACCOUNT],
				path: '/user-management/clients'
			}
		]
	},
	{
		id: 'channels',
		label: 'channels',
		icon: 'task',
		auth: [ACCESS_TAGS.GET_ALL_CHANNELS],
		path: '/channels'
	},
	{
		id: 'global-query',
		label: 'global_query',
		icon: 'insert_page_break',
		auth: [ACCESS_TAGS.GET_GLOBAL_QUERY],
		path: '/global-query-list'
	},
	{
		id: 'customized-queries',
		label: 'customized_queries',
		icon: 'dashboard_customize',
		auth: [ACCESS_TAGS.GET_CUSTOM_QUERY],
		path: '/customized-queries'
	},
	{
		id: 'statistics',
		label: 'statistics',
		icon: 'area_chart',
		auth: [
			ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT,
			ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT,
			ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA
		],
		children: [
			{
				id: 'single-analysis',
				label: 'single_analysis',
				icon: 'remove',
				auth: [ACCESS_TAGS.GET_SINGLE_ANALYSIS_REPORT],
				path: '/statistics/single-analysis'
			},
			{
				id: 'delta-analysis',
				label: 'delta_analysis',
				icon: 'remove',
				auth: [ACCESS_TAGS.GET_DELTA_ANALYSIS_REPORT],
				path: '/statistics/delta-analysis'
			},
			{
				id: 'sp-attribution-entry',
				label: 'sp_attribution_entry',
				icon: 'remove',
				auth: [ACCESS_TAGS.GET_CP_ATTRIBUTE_SEARCH_DATA],
				path: '/statistics/sp-attribution-entry'
			}
		]
	},
	{
		id: 'daily-report',
		label: 'daily_report',
		icon: 'area_chart',
		auth: [ACCESS_TAGS.DAILY_CHANNEL_REPORT,ACCESS_TAGS.DAILY_FUNNEL_REPORT],
		children: [
			{
				id: 'funnel-report',
				label: 'funnel_report',
				icon: 'remove',
				auth: [ACCESS_TAGS.DAILY_FUNNEL_REPORT],
				path: '/daily-report/funnel-report'
			},
			{
				id: 'channel-report',
				label: 'channel_report',
				icon: 'remove',
				auth: [ACCESS_TAGS.DAILY_CHANNEL_REPORT],
				path: '/daily-report/channel-report'
			}
		]
	}
];
