import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from 'components/atoms/Loader';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';
import { ReportTableCell } from 'components/atoms/CPReportTableCell';
import { IColumn } from '../DataTable/DataTable.type';

interface IFunnelReportTable {
	columns: IColumn[];
	rows: any;
	loading?: boolean;
}

export default function FunnelReportTable(props: IFunnelReportTable) {
	const { rows, columns, loading } = props;
	const { t } = useTranslation();

	const formatColumnValue = (type: any, value: any) => {
		if (type === 'currency' && value) return formatCurrency(value);
		else if (type === 'percentage' && value) return `${value}%`;
		else if (value) return value.toString();
		else return '-';
	};
	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: '100%'
				}
			}}
		>
			<Table
				stickyHeader
				sx={{ width: '100%', tableLayout: 'auto', borderCollapse: 'Collapse', borderStyle: 'hidden' }}
				aria-label="label-table"
			>
				<TableHead>
					<TableRow sx={{}}>
						{columns.map((column) => (
							<TableCell
								key={column.id}
								align={'center'}
								sx={{
									minWidth: column.minWidth,
									...column.headerStyle
								}}
							>
								<Tooltip title={column.label} placement="bottom-start">
									<span>{column.label}</span>
								</Tooltip>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{rows && rows.length > 0 && !loading
						? rows.map((row: any, index: number) => (
								<TableRow key={`row-${index.toString()}`}>
									{columns.map((column) => {
										const value = row[column.id as keyof typeof row];
										return (
											<ReportTableCell
												key={column.id}
												sx={{
													textAlign: column.align,
													minWidth: column.minWidth,
													padding: '6px 16px !important',
													...column.style
												}}
											>
												<span>{formatColumnValue(column.type, value)}</span>
											</ReportTableCell>
										);
									})}
								</TableRow>
						  ))
						: !loading && (
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
										<Typography align="center">{t('no_records_found')}</Typography>
									</TableCell>
								</TableRow>
						  )}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
