import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// translations
const resources = {
	en: {
		translation: {
			userType: 'User type',
			client: 'Client',
			email: 'Email',
			value_required: 'This field cannot be empty',
			name: 'Name',
			invalid_email: 'Invalid email address',
			invalid_name: 'Invalid name',
			error: 'Error',
			successful: 'Successful',
			new_user_account_created: 'New user account has been created.',
			went_wrong: 'Something went wrong. Please try again later',
			client_name_required: 'Client Name is required',
			no_records_found: 'No Records Found',
			no_records: 'No Records',
			create_user_accounts: 'Create User Account',
			create_user_account_confirm: 'Are you sure you want to create this user account ?',
			go_back: 'Go Back',
			save: 'Save',
			cancel: 'Cancel',
			delete: 'Delete',
			delete_user_account: 'Delete user accounts',
			delete_user_account_confirm: 'Are you sure you want to delete this user account ?',
			delete_user_account_confirm_info: 'This will permanently erase this account.',
			accounts: 'Accounts',
			delete_successfully_user_account: 'Successfully deleted the user account',
			delete_successfully_user_account_message: 'User account has been permanently removed.',
			client_name: 'Client name',
			client_email: 'Client email',
			Select_analyst: 'Select analyst',
			edit_user_accounts: 'Edit user accounts',
			create_user: 'Create User',
			update: 'Update',
			update_user_account_confirm: 'Are you sure, you want to update this user account ?',
			information_updated: 'Information updated',
			update_successfully_user_account_message: 'User account information has been updated.',
			successfully_deleted: 'Successfully Deleted',
			delete_successfully_client: 'Successfully deleted the client ',
			delete_successfully_client_message: 'Client has been permanently removed.',
			funnel_name: 'Funnel',
			labels: 'Labels',
			ranks: 'Ranks',
			multiplier: 'Multiplier',
			fixedValue: 'Fixed value',
			fixedROAS: 'Fixed ROAS',
			delete_successfully_channel: 'Successfully deleted the channel',
			delete_successfully_channel_message: 'Channel has been permanently removed.',
			clients: 'Clients',
			user_management: 'User Management',
			google_analytics: 'Google Analytics',
			create_new_query_google_analytics_info_line: `The source, medium, and campaign fields are either within brackets [] or within quotation marks '""'. Brackets may identify the exact match. And quotation marks may identify a phrase match.`,
			ad_platform: 'Ad platform',
			create_new_query_ad_platform_info: `The campaign field is either within brackets [] or within quotation marks '""'. Brackets may identify the exact match. And quotation marks may identify a phrase match.`,
			split: 'Split',
			query_name: 'Query name',
			source: 'Source',
			medium: 'Medium',
			campaign: 'Campaign',
			add_more: 'add more',
			channel_name: 'Channel name',
			split_percentage: 'Split %',
			create_new_query: 'Create new query',
			channels: 'Channels',
			global_query: 'Global Queries',
			label: 'label',
			rank: 'Rank',
			cannot_exceed_255: 'Cannot exceed 255 characters',
			cannot_exceed_100: 'Cannot exceed 100 characters',
			cannot_exceed_15: 'Cannot exceed 15 characters',
			create_query: 'Create Query',
			validation_within_brackets_or_commas:
				'Details should be inserted either within brackets [] or within quotation marks " ” "',
			google_analytics_common_error:
				"Minimum one field needs to be filled, if the query does not have any 'Ad Platforms'",
			ad_platform_common_error: "This field needs to be filled, if the query does not have any 'Google Analytics'",
			split_needs_balanced_100: 'Split needs to be balanced at 100%',
			split_cannot_0_percentage: "Split value cannot be '0%'",
			channel_selection_cannot_empty: 'Channel selection cannot be empty',
			create_query_list: 'Create query list',
			create_global_query_confirm: 'Are you sure you want to create this query ?',
			new_query_created: 'New query has been created.',
			deleted_global_query_title: 'Successfully deleted the query list',
			delete_successfully_global_query_message: 'Query has been removed.',
			delete_global_query: 'Delete query',
			delete_global_query_confirm: 'Are you sure you want to delete this query ?',
			edit_global_query: 'Edit query',
			update_global_query_confirm: 'Are you sure, you want to update this global query ?',
			update_successfully_global_query_message: 'Query information has been updated.',
			edit: 'Edit',
			view_global_query: 'View query',
			close: 'Close',
			view: 'View',
			customized_queries: 'Customized Queries',
			create_customized_query: 'Create Customized Query',
			channel_delete_confirmation: 'Are you sure you want to delete the Channel ?',
			delete_channel_title: 'Delete Channel',
			delete_custom_query_title: 'Delete customized query',
			delete_custom_query_confirm: 'Are you sure you want to delete this customized query ?',
			deleted_custom_query_title: 'Successfully deleted the customized query',
			delete_successfully_custom_query_message: 'Customized query has been removed.',
			channel_info_updated: 'Channel information has been updated.',
			statistics: 'Statistics',
			sp_attribution_entry: 'SP Attribution Entry',
			single_analysis: 'Single Analysis',
			revenue: 'Revenue',
			roas: 'ROAS',
			prospecting_channels: 'Prospecting Channels',
			conversion_rate: 'Conversion Rate',
			cost_per_acquisition: 'Cost per Acquisition',
			table: 'Table',
			view_channel: 'View Channel',
			create_channel: 'Create Channel',
			edit_channel_confirmation: 'Are you sure you want to update this channel ?',
			create_channel_confirmation: 'Are you sure you want to create this channel ?',
			export: 'Export',
			edit_channel: 'Edit Channel',
			acquisition: 'Acquisition',
			spend: 'Spend',
			ga_value: 'GA Value',
			mulitiplier: 'Mulitiplier',
			fixed_value: 'Fixed Value',
			fixed_roas: 'Fixed ROAS',
			new_value: 'New Value',
			total: 'Total',
			assigned_total_value: 'Assigned total Value',
			unassigned_total_value: 'Unassigned total Value',
			unassigned_total_per: 'Unassigned total per',
			search: 'Search',
			custom_query_search_info1: 'Need to select client to start the search',
			start_date: 'Start date',
			end_date: 'End date',
			invalid_date: 'Invalid date',
			show_only_unmapped: 'Show only unmapped',
			advanced_search: 'Advanced search',
			custom_query_search_info2:
				'The source, medium, and campaign fields are either within brackets [] or within inverted commas " ” ". Brackets may identify the exact match. And inverted commas may identify a phrase match.',
			sales: 'Sales',
			spends: 'Spends',
			campaign_names_match: 'Campaign names match',
			action: 'Action',
			end_date_cannot_be: "End date can't be before start date",
			clientName: 'Client name',
			campaign_names_do_not_match: 'Campaign names don’t match',
			unmapped_queries: 'Unmapped queries',
			need_to_select_client_to_start_the_search: 'Need to select client to start the search',
			default_value_null: 'Default value / Null',
			previously_updated: 'Previously updated',
			updated_value: 'Updated value',
			please_save_the_changes: 'Please save the changes...',
			create_new_custom_query: 'Create customized query',
			map_all_unmapped: 'Map all unmapped',
			edit_customized_query: 'Edit customized query',
			create_customized_query_confirm: 'Are you sure you want to create this customized query ?',
			update_customized_query_confirm: 'Are you sure you want to edit this customized query?',
			are_you_sure_you_want_to_save_the_changes: 'Are you sure you want to save the changes?',
			you_have_updated_n_records: 'You have updated {{count}} record/s',
			successfully_updated_n_records: 'Successfully updated {{count}} record/s',
			delta_analysis: 'Delta Analysis',
			datatable1: 'Data Table 1',
			datatable2: 'Data Table 2',
			table1: 'Table 1',
			table2: 'Table 2',
			spend_change: 'Spend Change',
			revenue_change: 'Revenue Change',
			spend_hange_percentage: 'Spend Change %',
			rev_change_percentage: 'Rev Change %',
			Change_percentage: 'Change %',
			filterby_label: 'Filter by label',
			cost: 'Cost',
			ga_revenue: 'GA Revenue',
			new_customized_query_created: 'New customized query has been created.',
			investment: 'Cost',
			cp_after_sales: 'SP Attributed Sales',
			cp_after_roas: 'SP Attributed ROAS',
			ga_roas: 'GA ROAS',
			platform_revenue: 'Platform Revenue',
			platform_roas: 'Platform ROAS',
			view_customized_query: 'View customized query',
			invalid_number_format: 'Number can contain only 12 max integers with 2 decimal points',
			are_you_sure_you_want_to_leave: 'Are you sure you want to leave?',
			you_have_n_unsaved_changes: 'You have unsaved change(s)',
			account_group_Id: 'Account group id',
			invalid_external_client_id: 'Invalid external client ID',
			number_must_greater_than_0: 'The number must be greater than 0!',
			no_active_account: 'No active account! Verify a user has been signed in.',
			audit_trail: 'Audit Trail',
			sp_attributed: 'SP Attributed',
			ga_last_click: 'GA Last Click',
			platform_statistics: 'Platform Statistics',
			total_all_channels: 'Total All Channels',
			successfullySaved: 'Successfully Updated',
			loading_labels_reports: 'Loading Label Reports...',
			notifications: 'Notifications',
			log_out: 'Log out',
			my_profile: 'My Profile',
			change_password: 'Change Password',
			channelType: 'Channel Type',
			costPercentage: '% Cost',
			clickability_factor_multiple: 'Clickability Factor Multiple',
			delete_query_Info:
				'Doing so may cause SP channels to go unmapped and that unless another subordinate query picks up the s/m/c’s that are now unmapped, a new query will be needed to map the newly unmapped s/m/c’s to a SP channel',
			daily_report: 'Daily Report',
			funnel_report: 'Funnel Report',
			channel_report: 'Channel Report',
			date: 'Date',
			total_sales: 'Total Sales',
			awareness: 'Awareness',
			conversion: 'Conversion',
			navigation: 'Navigation',
			total_spends: 'Total Spends',
			sitewide_cr_test_period: 'Sitewide CR during test Period',
			view_incremental_schedule: 'View Schedules',
			spend_level_min: 'Spend Level Min',
			spend_level_max: 'Spend Level Max',
			channel_roas: 'Channel ROAS',
			asOfDate: 'As of Date'
		}
	},
	fr: {
		translation: {
			value_required: 'Value Required',
			name: 'Name'
		}
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en', // language to use

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
