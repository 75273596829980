import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { ReportTableCell, TotalReportTableCell } from 'components/atoms/CPReportTableCell';
import Loader from 'components/atoms/Loader';
import _ from 'lodash';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';
import { IColumn } from '../DataTable/DataTable.type';

const ReportTable = (props: any) => {
	const { columns, rows, totalRow, funnel, loading, isShowChannels = true, grandTotalRow } = props;
	const { t } = useTranslation();

	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: isShowChannels ? '1600px' : '1400px'
				}
			}}
		>
			<Table stickyHeader aria-label="a dense table" sx={{ width: '100%', tableLayout: 'auto' }}>
				<TableHead>
					<TableRow
						sx={{
							'& th': {
								borderRadius: '6px 6px 0 0'
							}
						}}
					>
						{isShowChannels ? (
							<TableCell
								sx={{
									backgroundColor: get(funnel, 'color'),
									borderRight: 10,
									borderColor: '#FFF',
									borderBottom: 0,
									fontWeight: 500,
									fontSize: '14px',
									padding: '6px 16px !important',
									color: '#FFFFFF',
									width: '600px'
								}}
								align="left"
								colSpan={4}
							>
								{get(funnel, 'funnelName')} - {get(funnel, 'description')}
							</TableCell>
						) : (
							<TableCell sx={{ width: '40%' }} colSpan={3}></TableCell>
						)}
						<TableCell
							sx={{
								backgroundColor: '#F9F9F9',
								borderLeft: 10,
								borderRight: 10,
								borderBottom: 0,
								borderColor: '#FFF',
								fontWeight: 500,
								fontSize: '14px',
								padding: '6px 16px !important',
								color: '#363636',
								width: '20%'
							}}
							align="left"
							colSpan={2}
						>
							{t('sp_attributed')}
						</TableCell>
						<TableCell
							sx={{
								backgroundColor: '#F9F9F9',
								borderLeft: 10,
								borderRight: 10,
								borderColor: '#FFF',
								fontWeight: 500,
								fontSize: '14px',
								padding: '6px 16px !important',
								color: '#363636',
								width: '20%'
							}}
							align="left"
							colSpan={2}
						>
							{t('ga_last_click')}
						</TableCell>
						<TableCell
							sx={{
								backgroundColor: '#F9F9F9',
								borderLeft: 10,
								borderColor: '#FFF',
								fontWeight: 500,
								fontSize: '14px',
								padding: '6px 16px !important',
								color: '#363636',
								width: '20%'
							}}
							align="left"
							colSpan={2}
						>
							{t('platform_statistics')}
						</TableCell>
					</TableRow>
					<TableRow>
						{columns.map(
							(column: IColumn) =>
								!column.isHidden && (
									<TableCell
										sx={{
											backgroundColor: get(funnel, 'color'),
											top: 0,
											minWidth: column.minWidth,
											maxWidth: column.maxWidth,
											fontSize: '12px',
											fontWeight: 600,
											padding: '2px 16px !important',
											color: '#FFFFFF'
										}}
										key={column.id}
										align={column.align}
									>
										{column.label}
									</TableCell>
								)
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.length === 0 && !loading && (
						<TableRow>
							<TableCell
								component="th"
								scope="row"
								colSpan={columns.length}
								sx={{ padding: '6px 16px !important', width: '100%' }}
							>
								<Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#363636' }} align="center">
									{t('no_records')}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{loading && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{rows.length > 0 &&
						!loading &&
						rows.map((row: any) => (
							<TableRow key={row.channelName}>
								{isShowChannels && (
									<ReportTableCell
										sx={{ minWidth: '300px', maxWidth: '300px', textAlign: 'left', textOverflow: 'ellipsis' }}
									>
										<Tooltip
											title={
												row.channelName !== undefined && row.channelName !== null ? row.channelName.toString() : ''
											}
											placement="bottom-start"
										>
											<span
												style={{
													fontSize: '12px',
													fontWeight: 400,
													color: '#000000'
												}}
											>
												{row.channelName ? row.channelName.toString() : '-'}
											</span>
										</Tooltip>
									</ReportTableCell>
								)}
								<ReportTableCell>{formatCurrency(row.cost)}</ReportTableCell>
								<ReportTableCell>{Math.round(row.conversionRate * 100) / 100}%</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.costPerAcquisition)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5',
										fontWeight: 600,
										backgroundColor: '#C0FFBA'
									}}
								>
									{formatCurrency(row.spAttributeRevenue)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										fontWeight: 600,
										backgroundColor: '#C0FFBA',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{row.spAttributeRoas}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.gaRevenue)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{row.gaRoas}
								</ReportTableCell>
								<ReportTableCell
									align="right"
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.platformRevenue)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{row.platformRoas}
								</ReportTableCell>
							</TableRow>
						))}
					{!loading && (
						<>
							{/* <TableFooter sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}> */}
							<TableRow sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}>
								{isShowChannels && <TotalReportTableCell sx={{ textAlign: 'left' }}>Total</TotalReportTableCell>}
								<TotalReportTableCell>
									{totalRow.cost ? formatCurrency(totalRow.cost) : formatCurrency(0)}
								</TotalReportTableCell>
								<TotalReportTableCell>{totalRow.conversionRate ? totalRow.conversionRate : 0}%</TotalReportTableCell>
								<TotalReportTableCell sx={{ borderRight: 10, borderColor: '#E5E5E5' }}>
									{totalRow.costPerAcquisition ? formatCurrency(totalRow.costPerAcquisition) : formatCurrency(0)}
								</TotalReportTableCell>

								<TotalReportTableCell>
									{totalRow.gaRevenue ? formatCurrency(totalRow.spAttributeRevenue) : formatCurrency(0)}
								</TotalReportTableCell>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{totalRow.spAttributeRoas ? totalRow.spAttributeRoas : 0}
								</TotalReportTableCell>

								<TotalReportTableCell>
									{totalRow.gaRevenue ? formatCurrency(totalRow.gaRevenue) : formatCurrency(0)}
								</TotalReportTableCell>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{totalRow.gaRoas ? totalRow.gaRoas : 0}
								</TotalReportTableCell>

								<TotalReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
									align="right"
								>
									{totalRow.platformRevenue ? formatCurrency(totalRow.platformRevenue) : formatCurrency(0)}
								</TotalReportTableCell>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5',
										minWidth: '138px',
										maxWidth: '138px'
									}}
								>
									{totalRow.platformRoas ? totalRow.platformRoas : 0}
								</TotalReportTableCell>
							</TableRow>
							{grandTotalRow && (
								<>
									<TableRow>
										<TableCell colSpan={columns.length}></TableCell>
									</TableRow>
									<TableRow sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}>
										{isShowChannels && (
											<TotalReportTableCell sx={{ textAlign: 'left' }}>{t('total_all_channels')}</TotalReportTableCell>
										)}
										<TotalReportTableCell>
											{grandTotalRow.cost ? formatCurrency(grandTotalRow.cost) : formatCurrency(0)}
										</TotalReportTableCell>
										<TotalReportTableCell>
											{grandTotalRow.conversionRate ? grandTotalRow.conversionRate : 0}%
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{grandTotalRow.costPerAcquisition
												? formatCurrency(grandTotalRow.costPerAcquisition)
												: formatCurrency(0)}
										</TotalReportTableCell>

										<TotalReportTableCell
											sx={{
												borderLeft: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{grandTotalRow.spAttributeRevenue
												? formatCurrency(grandTotalRow.spAttributeRevenue)
												: formatCurrency(0)}
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5',
												minWidth: '138px',
												maxWidth: '138px'
											}}
											align="right"
										>
											{grandTotalRow.spAttributeRoas ? grandTotalRow.spAttributeRoas : 0}
										</TotalReportTableCell>

										<TotalReportTableCell
											sx={{
												borderLeft: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{grandTotalRow.gaRevenue ? formatCurrency(grandTotalRow.gaRevenue) : formatCurrency(0)}
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5',
												minWidth: '138px',
												maxWidth: '138px'
											}}
										>
											{grandTotalRow.gaRoas ? grandTotalRow.gaRoas : 0}
										</TotalReportTableCell>

										<TotalReportTableCell
											sx={{
												borderLeft: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{grandTotalRow.platformRevenue
												? formatCurrency(grandTotalRow.platformRevenue)
												: formatCurrency(0)}
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5',
												minWidth: '138px',
												maxWidth: '138px'
											}}
										>
											{grandTotalRow.platformRoas ? grandTotalRow.platformRoas : 0}
										</TotalReportTableCell>
									</TableRow>
								</>
							)}
						</>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ReportTable;
