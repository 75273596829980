import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from 'components/atoms/Loader';
import { formatCurrency } from 'utils/commonUtil';
import { ReportTableCell, TotalReportTableCell } from 'components/atoms/CPReportTableCell';

const ComparisonTable = (props: any) => {
	const { columns, rows, totalRow, loading, grandTotalRow } = props;
	const { t } = useTranslation();

	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: '750px'
				}
			}}
		>
			<Table stickyHeader aria-label="a dense table" sx={{ tableLayout: 'unset' }}>
				<TableHead>
					<TableRow>
						<TableCell
							sx={{
								backgroundColor: '#FFF',
								padding: '6px 16px !important'
							}}
							align="right"
							colSpan={5}
						>
							<br />
						</TableCell>
					</TableRow>
					<TableRow>
						<TotalReportTableCell
							sx={{
								backgroundColor: '#E5E5E5'
							}}
							align="right"
						>
							{t('spend_change')}
						</TotalReportTableCell>
						<TotalReportTableCell
							sx={{
								backgroundColor: '#E5E5E5'
							}}
						>
							{t('revenue_change')}
						</TotalReportTableCell>
						<TotalReportTableCell
							sx={{
								backgroundColor: '#E5E5E5'
							}}
						>
							{t('spend_hange_percentage')}
						</TotalReportTableCell>
						<TotalReportTableCell
							sx={{
								backgroundColor: '#E5E5E5'
							}}
						>
							{t('rev_change_percentage')}
						</TotalReportTableCell>
						<TotalReportTableCell
							sx={{
								backgroundColor: '#E5E5E5'
							}}
							align="right"
						>
							{t('Change_percentage')}
						</TotalReportTableCell>
					</TableRow>
				</TableHead>
				<TableBody sx={{ marginBottom: 10 }}>
					{rows.length === 0 && !loading && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#363636' }} align="center">
									{'No Records'}
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{loading && (
						<TableRow>
							<TableCell component="th" scope="row" colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{!loading &&
						rows.map((row: any, index: number) => (
							<TableRow key={index}>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.spendChange)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{formatCurrency(row.revenueChange)}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.spendChangePercentage}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.revChangePercentage}
								</ReportTableCell>
								<ReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{row.changePercentage}
								</ReportTableCell>
							</TableRow>
						))}
					{!loading && (
						<>
							<TableRow sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
									align="right"
								>
									{formatCurrency(totalRow.spendChange ? totalRow.spendChange : 0)}
								</TotalReportTableCell>
								<TotalReportTableCell>
									{formatCurrency(totalRow.revenueChange ? totalRow.revenueChange : 0)}
								</TotalReportTableCell>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
									align="right"
								>
									{totalRow.spendChangePercentage}
								</TotalReportTableCell>

								<TotalReportTableCell
									sx={{
										borderLeft: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{totalRow.revChangePercentage}
								</TotalReportTableCell>
								<TotalReportTableCell
									sx={{
										borderRight: 10,
										borderColor: '#E5E5E5'
									}}
								>
									{totalRow.changePercentage}
								</TotalReportTableCell>
							</TableRow>
							{grandTotalRow && (
								<>
									<TableRow>
										<TableCell colSpan={columns.length}></TableCell>
									</TableRow>
									<TableRow
										sx={{
											minWidth: '170px',
											maxWidth: '170px',
											backgroundColor: '#E5E5E5',
											zIndex: 2,
											position: 'sticky',
											left: 0,
											bottom: 0
										}}
									>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{formatCurrency(grandTotalRow.spendChange ? grandTotalRow.spendChange : 0)}
										</TotalReportTableCell>
										<TotalReportTableCell>
											{formatCurrency(grandTotalRow.revenueChange ? grandTotalRow.revenueChange : 0)}
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5'
											}}
										>
											{grandTotalRow.spendChangePercentage}
										</TotalReportTableCell>

										<TotalReportTableCell
											sx={{
												borderLeft: 10,
												borderColor: '#E5E5E5'
											}}
											align="right"
										>
											{grandTotalRow.revChangePercentage}
										</TotalReportTableCell>
										<TotalReportTableCell
											sx={{
												borderRight: 10,
												borderColor: '#E5E5E5'
											}}
											align="right"
										>
											{grandTotalRow.changePercentage}
										</TotalReportTableCell>
									</TableRow>
								</>
							)}
						</>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ComparisonTable;
