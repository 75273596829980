import { List, ListItemButton, ListItemText } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IUserProfilePopOver {
	anchorEl: HTMLButtonElement | null;
	handleClose: () => void;
}
export default function UserProfilePopOver(props: IUserProfilePopOver) {
	const { anchorEl, handleClose } = props;
	const open = Boolean(anchorEl);
	const { t } = useTranslation();
	const id = open ? 'user-popover' : undefined;
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate('/my-profile');
		handleClose();
	};

	return (
		<div>
			<Popover
				id={id}
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<List sx={{ width: '200px', padding: 0 }}>
					<ListItemButton onClick={handleProfileClick}>
						<ListItemText primary={t('my_profile')} />
					</ListItemButton>
					<ListItemButton>
						<ListItemText primary={t('change_password')} />
					</ListItemButton>
				</List>
			</Popover>
		</div>
	);
}
