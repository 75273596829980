import { KeyBoardKeys } from 'common/enums/keybordKeys.enum';
import { IOptionItem } from 'interfaces/optionItem.interface';

export const isAuthRoute = (accessList: string[], authList: string[]) => {
	return authList.some((value) => accessList.includes(value));
};

export const isAuthOperation = (accessList: string[], operationTag: string) => {
	return accessList.includes(operationTag);
};

export const getDownloadFileName = (headerValue: string) => {
	let filename = '';
	if (headerValue && headerValue.indexOf('attachment') !== -1) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		var matches = filenameRegex.exec(headerValue);
		if (matches !== null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}
	return filename;
};

export const formatCurrency = (
	amount: number,
	languageCode: string = 'en',
	countryCode: string = 'us',
	currencyCode: string = 'USD'
) => {
	return Intl.NumberFormat(`${languageCode}-${countryCode}`, {
		currency: currencyCode,
		style: 'currency'
	}).format(amount);
};

export const preventKeyboardEventPressE = (evt: any) => evt.key === 'e' && evt.preventDefault();

export const AllowOnlyNumberKeys = (e: any) => {
	const re = /[0-9]+/;
	if (
		re.test(e.key) ||
		e.key == KeyBoardKeys.Backspace ||
		e.key == KeyBoardKeys.Dot ||
		e.key == KeyBoardKeys.ArrowLeft ||
		e.key == KeyBoardKeys.ArrowRight
	) {
		return true;
	}
	e.preventDefault();
};

export const getRanks = () => {
	var ranks: IOptionItem[] = [];

	for (let i = 1; i <= 100; i++) {
		ranks.push({ label: i.toString(), value: i });
	}
	return ranks;
};
