import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from 'components/atoms/Loader';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';
import { ReportTableCell } from 'components/atoms/CPReportTableCell';
import { IColumn } from '../DataTable/DataTable.type';

interface IChannelReportTable {
	columns: IColumn[];
	rows: any;
	loading?: boolean;
	channels: any;
	channelData: any;
}

export default function ChannelReportTable(props: IChannelReportTable) {
	const { rows, columns, loading, channels, channelData } = props;
	const { t } = useTranslation();

	const formatColumnValue = (type: any, value: any) => {
		if (type === 'currency' && value) return formatCurrency(value);
		else if (type === 'percentage' && value) return `${value}%`;
		else if (value) return value.toString();
		else return '-';
	};

	const TotalColumns = () => {
		let awarenessCol = channels?.uniqueAwarenessChannels?.length ? channels?.uniqueAwarenessChannels?.length - 1 : 0;
		let acquisitionCol = channels?.uniqueAcquisitionChannels?.length
			? channels?.uniqueAcquisitionChannels?.length - 1
			: 0;
		let conversionCol = channels?.uniqueConversionChannels?.length ? channels?.uniqueConversionChannels?.length - 1 : 0;
		let navigationCol = channels?.uniqueNavigationChannels?.length ? channels?.uniqueNavigationChannels?.length - 1 : 0;
		let totalColumns = awarenessCol + acquisitionCol + conversionCol + navigationCol + columns.length;
		return totalColumns;
	};

	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: '100%'
				}
			}}
		>
			<Table
				stickyHeader
				sx={{
					width: '100%',
					tableLayout: channels ? 'auto' : 'fixed',
					borderStyle: 'hidden'
				}}
				aria-label="label-table"
			>
				<TableHead>
					<TableRow>
						{columns.map((column) => (
							<TableCell
								colSpan={column.colSpan}
								key={column.id}
								align={'center'}
								sx={{
									minWidth: column.minWidth,
									...column.headerStyle
								}}
							>
								<Tooltip title={column.label} placement="bottom-start">
									<span>{column.label}</span>
								</Tooltip>
							</TableCell>
						))}
					</TableRow>
					{rows.length > 0 && !loading && (
						<TableRow>
							<TableCell
								key={'channelHeaderRowDate'}
								sx={{
									padding: '5px',
									backgroundColor: '#ffffff',
									borderRight: 10,
									borderRightColor: '#ffffff'
								}}
							/>
							<TableCell
								key={'channelHeaderRowTotalSales'}
								sx={{
									padding: '5px',
									backgroundColor: '#ffffff',
									borderRight: 10,
									borderRightColor: '#ffffff'
								}}
							/>
							{channels?.uniqueAwarenessChannels?.length > 0 ? (
								channels?.uniqueAwarenessChannels.map((channel: string, index: any) => (
									<TableCell
										key={channel}
										align={'center'}
										sx={{
											whiteSpace: 'nowrap',
											padding: '5px',
											backgroundColor: '#e6b8004d',
											...(channels?.uniqueAwarenessChannels?.length - 1 === index && {
												borderRight: 10,
												borderRightColor: '#ffffff'
											})
										}}
									>
										<Tooltip title={channel} placement="bottom-start">
											<span>{channel}</span>
										</Tooltip>
									</TableCell>
								))
							) : (
								<TableCell
									key={'channelHeaderRowAwareness'}
									align={'center'}
									sx={{
										padding: '5px',
										whiteSpace: 'nowrap',
										backgroundColor: '#e6b8004d',
										borderRight: 10,
										borderRightColor: '#ffffff'
									}}
								></TableCell>
							)}
							{channels?.uniqueAcquisitionChannels?.length > 0 ? (
								channels?.uniqueAcquisitionChannels.map((channel: string, index: any) => (
									<TableCell
										key={channel}
										align={'center'}
										sx={{
											padding: '5px',
											whiteSpace: 'nowrap',
											backgroundColor: '#7a7a7a4d',
											...(channels?.uniqueAcquisitionChannels?.length - 1 === index && {
												borderRight: 10,
												borderRightColor: '#ffffff'
											})
										}}
									>
										<Tooltip title={channel} placement="bottom-start">
											<span>{channel}</span>
										</Tooltip>
									</TableCell>
								))
							) : (
								<TableCell
									key={'channelHeaderRowAcquisition'}
									align={'center'}
									sx={{
										padding: '5px',
										backgroundColor: '#7a7a7a4d',
										whiteSpace: 'nowrap',
										borderRight: 10,
										borderRightColor: '#ffffff'
									}}
								></TableCell>
							)}
							{channels?.uniqueConversionChannels?.length > 0 ? (
								channels?.uniqueConversionChannels.map((channel: string, index: any) => (
									<TableCell
										key={channel}
										align={'center'}
										sx={{
											padding: '5px',
											whiteSpace: 'nowrap',
											backgroundColor: '#7b88ff4d',
											...(channels?.uniqueConversionChannels?.length - 1 === index && {
												borderRight: 10,
												borderRightColor: '#ffffff'
											})
										}}
									>
										<Tooltip title={channel} placement="bottom-start">
											<span>{channel}</span>
										</Tooltip>
									</TableCell>
								))
							) : (
								<TableCell
									key={'channelHeaderRowConversion'}
									align={'center'}
									sx={{
										padding: '5px',
										whiteSpace: 'nowrap',
										backgroundColor: '#7b88ff4d',
										borderRight: 10,
										borderRightColor: '#ffffff'
									}}
								></TableCell>
							)}
							{channels?.uniqueNavigationChannels?.length > 0 ? (
								channels?.uniqueNavigationChannels.map((channel: string, index: any) => (
									<TableCell
										key={channel}
										align={'center'}
										sx={{
											padding: '5px',
											whiteSpace: 'nowrap',
											backgroundColor: '#20af5a4d',
											...(channels?.uniqueNavigationChannels?.length - 1 === index && {
												borderRight: 10,
												borderRightColor: '#ffffff'
											})
										}}
									>
										<Tooltip title={channel} placement="bottom-start">
											<span>{channel}</span>
										</Tooltip>
									</TableCell>
								))
							) : (
								<TableCell
									key={'channelHeaderRowNavigation'}
									align={'center'}
									sx={{
										padding: '5px',
										backgroundColor: '#20af5a4d',
										borderRight: 10,
										whiteSpace: 'nowrap',
										borderRightColor: '#ffffff'
									}}
								></TableCell>
							)}
							<TableCell
								sx={{
									padding: '5px',
									backgroundColor: '#ffffff'
								}}
							/>
						</TableRow>
					)}
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={TotalColumns()} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{rows && rows.length > 0 && !loading
						? rows.map((row: any, index: number) => (
								<TableRow key={`row-${index.toString()}`}>
									<ReportTableCell
										key={row.date + index}
										sx={{
											textAlign: 'left',
											padding: '6px 16px !important',
											borderRight: 10,
											borderRightColor: '#ffffff'
										}}
									>
										<span>{formatColumnValue('date', row.date)}</span>
									</ReportTableCell>
									<ReportTableCell
										key={row.totalSales + index}
										sx={{
											textAlign: 'right',
											padding: '6px 16px !important',
											borderRight: 10,
											borderRightColor: '#ffffff'
										}}
									>
										<span>{formatColumnValue('currency', row.totalSales)}</span>
									</ReportTableCell>

									{channels?.uniqueAwarenessChannels && channels?.uniqueAwarenessChannels?.length > 0 ? (
										channels?.uniqueAwarenessChannels.map((col: any, colIndex: any) => {
											const value = channelData?.awarenessData[index][col] ? channelData?.awarenessData[index][col] : 0;
											return (
												<ReportTableCell
													key={value + index + col}
													sx={{
														textAlign: 'right',
														padding: '6px 16px !important',
														backgroundColor: '#e6b8004d',
														...(channels?.uniqueAwarenessChannels?.length - 1 === colIndex && {
															borderRight: 10,
															borderRightColor: '#ffffff'
														})
													}}
												>
													<span>{formatColumnValue('currency', value)}</span>
												</ReportTableCell>
											);
										})
									) : (
										<ReportTableCell
											key={'Empty' + index + 'awareness'}
											sx={{
												textAlign: 'right',
												padding: '6px 16px !important',
												backgroundColor: '#e6b8004d',
												borderRight: 10,
												borderRightColor: '#ffffff'
											}}
										>
											<span>{formatColumnValue('currency', 0)}</span>
										</ReportTableCell>
									)}
									{channels?.uniqueAcquisitionChannels && channels.uniqueAcquisitionChannels?.length > 0 ? (
										channels?.uniqueAcquisitionChannels.map((col: any, colIndex: any) => {
											const value = channelData?.acquisitionData[index][col]
												? channelData?.acquisitionData[index][col]
												: 0;
											return (
												<ReportTableCell
													key={value + index + col}
													sx={{
														textAlign: 'right',
														padding: '6px 16px !important',
														backgroundColor: '#7a7a7a4d',
														...(channels?.uniqueAcquisitionChannels?.length - 1 === colIndex && {
															borderRight: 10,
															borderRightColor: '#ffffff'
														})
													}}
												>
													<span>{formatColumnValue('currency', value)}</span>
												</ReportTableCell>
											);
										})
									) : (
										<ReportTableCell
											key={'Empty' + index + 'acquisition'}
											sx={{
												textAlign: 'right',
												padding: '6px 16px !important',
												backgroundColor: '#7a7a7a4d',
												borderRight: 10,
												borderRightColor: '#ffffff'
											}}
										>
											<span>{formatColumnValue('currency', 0)}</span>
										</ReportTableCell>
									)}

									{channels?.uniqueConversionChannels && channels?.uniqueConversionChannels?.length > 0 ? (
										channels.uniqueConversionChannels.map((col: any, colIndex: any) => {
											const value = channelData.conversionData[index][col] ? channelData.conversionData[index][col] : 0;
											return (
												<ReportTableCell
													key={value + index + col}
													sx={{
														textAlign: 'right',
														padding: '6px 16px !important',
														backgroundColor: '#7b88ff4d',
														...(channels?.uniqueConversionChannels?.length - 1 === colIndex && {
															borderRight: 10,
															borderRightColor: '#ffffff'
														})
													}}
												>
													<span>{formatColumnValue('currency', value)}</span>
												</ReportTableCell>
											);
										})
									) : (
										<ReportTableCell
											key={'Empty' + index + 'conversion'}
											sx={{
												textAlign: 'right',
												padding: '6px 16px !important',
												backgroundColor: '#7b88ff4d',
												borderRight: 10,
												borderRightColor: '#ffffff'
											}}
										>
											<span>{formatColumnValue('currency', 0)}</span>
										</ReportTableCell>
									)}

									{channels?.uniqueNavigationChannels && channels?.uniqueNavigationChannels?.length > 0 ? (
										channels?.uniqueNavigationChannels.map((col: any, colIndex: any) => {
											const value = channelData?.navigationData[index][col]
												? channelData.navigationData[index][col]
												: 0;
											return (
												<ReportTableCell
													key={value + index + col}
													sx={{
														textAlign: 'right',
														padding: '6px 16px !important',
														backgroundColor: '#20af5a4d',
														...(channels?.uniqueNavigationChannels.length - 1 === colIndex && {
															borderRight: 10,
															borderRightColor: '#ffffff'
														})
													}}
												>
													<span>{formatColumnValue('currency', value)}</span>
												</ReportTableCell>
											);
										})
									) : (
										<ReportTableCell
											key={'Empty' + index + 'navigation'}
											sx={{
												textAlign: 'right',
												padding: '6px 16px !important',
												backgroundColor: '#20af5a4d',
												borderRight: 10,
												borderRightColor: '#ffffff'
											}}
										>
											<span>{formatColumnValue('currency', 0)}</span>
										</ReportTableCell>
									)}

									<ReportTableCell
										key={row.totalSpends + index}
										sx={{
											textAlign: 'right',
											padding: '6px 16px !important'
										}}
									>
										<span>{formatColumnValue('currency', row.totalSpends)}</span>
									</ReportTableCell>
								</TableRow>
						  ))
						: !loading && (
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
										<Typography align="center">{t('no_records_found')}</Typography>
									</TableCell>
								</TableRow>
						  )}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
