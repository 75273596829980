import { IColumn } from 'components/molecules/DataTable/DataTable.type';
import FunnelReportTable from 'components/molecules/FunnelReportTable';
import { useTranslation } from 'react-i18next';

interface IFunnelReportContainer {
	FunnelReportDataList: any[];
	searchFunnnelReportLoading: boolean;
}
const FunnelReportContainer = (props: IFunnelReportContainer) => {
	const { t } = useTranslation();
	const { FunnelReportDataList: FunnelReportDataList, searchFunnnelReportLoading: searchFunnelReportLoading } = props;

	const funnelReportColumns: IColumn[] = [
		{
			id: 'date',
			label: t('date'),
			minWidth: '150px',
			align: 'left',
			style: { borderRight: 10, borderColor: '#E5E5E5', borderRightColor: '#ffffff' },
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#E5E5E5',
				borderRadius: '1em 1em 0em 0em',
				padding: '5px'
			}
		},
		{
			id: 'totalSales',
			label: t('total_sales'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 10, borderColor: '#E5E5E5', borderRightColor: '#ffffff' },
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#E5E5E5',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		},
		{
			id: 'awarenessSpends',
			label: t('awareness'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 10, borderColor: '#E5E5E5', backgroundColor: '#e6b8004d', borderRightColor: '#ffffff' },
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#e6b800',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		},
		{
			id: 'acquisitionSpends',
			label: t('acquisition'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 10, borderColor: '#E5E5E5', backgroundColor: '#7a7a7a4d', borderRightColor: '#ffffff' },
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#7a7a7a',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		},
		{
			id: 'conversionSpends',
			label: t('Conversion'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 10, borderColor: '#E5E5E5', backgroundColor: '#7b88ff4d', borderRightColor: '#ffffff' },
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#7b88ff',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		},
		{
			id: 'navigationSpends',
			label: t('Navigation'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: {
				borderRight: 10,
				borderColor: '#E5E5E5',
				backgroundColor: '#20af5a4d',
				borderRightColor: '#ffffff'
			},
			headerStyle: {
				borderRight: 10,
				borderColor: '#ffffff',
				backgroundColor: '#20af5a',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		},
		{
			id: 'totalSpends',
			label: t('total_spends'),
			minWidth: '150px',
			align: 'right',
			type: 'currency',
			style: { borderRight: 0 },
			headerStyle: {
				borderRight: 0,
				borderColor: '#ffffff',
				backgroundColor: '#E5E5E5',
				borderRadius: '1em 1em 0em 0em',
				padding: '0px'
			}
		}
	];

	return (
		<>
			<div style={{ marginBottom: '20px' }}>
				<FunnelReportTable columns={funnelReportColumns} loading={searchFunnelReportLoading} rows={FunnelReportDataList} />
			</div>
		</>
	);
};

export default FunnelReportContainer;
