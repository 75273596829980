import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { toFirstLetterCapital } from 'utils/helpers';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
	event.preventDefault();
	console.info('You clicked a breadcrumb.');
}

interface IBreadcrumb {
	title: string;
	actions?: React.ReactNode;
}

export default function Breadcrumb(props: IBreadcrumb) {
	const { title, actions } = props;
	const location = useLocation();
	const linkPath = location.pathname.split('/');
	const [breadcrumbs, setBreadcrumbs] = useState<ReactNode[]>();

	useEffect(() => {
		const pathArray = linkPath.map((path: any) => {
			let place = toFirstLetterCapital(path.replaceAll('-', '_'));
			if (path === '' || path === undefined) {
			} else {
				return (
					<Link underline="hover" key={path} color="inherit" href="/" onClick={handleClick} fontSize={'14px'}>
						{place}
					</Link>
				);
			}
		});
		if (pathArray.length === 2) {
			pathArray.push(<div key={'last'}></div>);
		}
		setBreadcrumbs(pathArray);
	}, []);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				backgroundColor: '#FFF',
				marginBottom: 2,
				borderRadius: '10px',
				padding: '16px 32px',
				boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.05)'
			}}
		>
			<div style={{}}>
				<Typography fontSize={'24px'} fontWeight={500}>
					{title}
				</Typography>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
			</div>
			<div style={{ float: 'right', alignSelf: 'center' }}>{actions}</div>
		</Stack>
	);
}
