export const ACCESS_TOKEN_KEY = 'auth.access_token';
export const X_USER_ID = 'X-User-ID';

const storage = {
	get: (key: string) => {
		return window.localStorage.getItem(key);
	},
	set: (key: string, val: string) => {
		return window.localStorage.setItem(key, val);
	},
	remove: (key: string) => {
		return window.localStorage.removeItem(key);
	}
};

const setAuthToken = (token: string) => {
	storage.set(ACCESS_TOKEN_KEY, token);
};

const getAuthToken = () => {
	return storage.get(ACCESS_TOKEN_KEY);
};

const removeAuthToken = () => {
	storage.remove(ACCESS_TOKEN_KEY);
};

const setXUserId = (xUserId: string) => {
	storage.set(X_USER_ID, xUserId);
};

const getXUserId = () => {
	return storage.get(X_USER_ID);
};

const clearLocalStorage = () => {
	localStorage.clear();
};

export { getAuthToken, setAuthToken, removeAuthToken, setXUserId, getXUserId, clearLocalStorage };
