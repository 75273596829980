import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IColumn } from '../DataTable/DataTable.type';
import Loader from 'components/atoms/Loader';
import { TableFooter, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/commonUtil';
import { ReportTableCell, TotalReportTableCell } from 'components/atoms/CPReportTableCell';

interface ILabelTable {
	columns: IColumn[];
	rows: any;
	totalRow: any;
	loading?: boolean;
	labelName?: string;
	hideLabel?: boolean;
	headerColor?: string;
}

export default function LabelTable(props: ILabelTable) {
	const theme = useTheme();
	const {
		rows,
		columns,
		loading,
		labelName,
		totalRow,
		hideLabel = false,
		headerColor = theme.palette.primary.labelReport.headerColor
	} = props;
	const { t } = useTranslation();

	const formatColumnValue = (type: any, value: any) => {
		if (type === 'currency' && value) return formatCurrency(value);
		else if (type === 'percentage' && value) return `${value}%`;
		else if (value) return value.toString();
		else return '-';
	};
	return (
		<TableContainer
			sx={{
				'&.MuiTableContainer-root': {
					width: !hideLabel ? '1600px' : '1400px'
				}
			}}
		>
			<Table stickyHeader sx={{ width: '100%', tableLayout: 'auto' }} aria-label="label-table">
				<TableHead>
					<TableRow sx={{ background: headerColor, color: theme.palette.primary.labelReport.fontColor }}>
						{!hideLabel && (
							<TotalReportTableCell
								sx={{
									textAlign: 'left'
								}}
							>
								{labelName}
							</TotalReportTableCell>
						)}
						{columns.map(
							(column, index) =>
								index !== 0 && (
									<TotalReportTableCell
										key={column.id}
										align={column.align}
										sx={{
											minWidth: column.minWidth
										}}
									>
										<Tooltip title={column.label} placement="bottom-start">
											<span>{column.label}</span>
										</Tooltip>
									</TotalReportTableCell>
								)
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
								<Loader />
							</TableCell>
						</TableRow>
					)}
					{rows && rows.length > 0 && !loading
						? rows.map((row: any, index: number) => (
								<TableRow key={`row-${index.toString()}`}>
									{(hideLabel ? columns.slice(1) : columns).map((column) => {
										const value = row[column.id as keyof typeof row];

										return (
											<ReportTableCell
												key={column.id}
												sx={{
													textAlign: column.align,
													minWidth: column.minWidth,
													padding: '6px 16px !important',
													...column.style
												}}
											>
												<span>{formatColumnValue(column.type, value)}</span>
											</ReportTableCell>
										);
									})}
								</TableRow>
						  ))
						: !loading && (
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ padding: '6px 16px !important' }}>
										<Typography align="center">{t('no_records_found')}</Typography>
									</TableCell>
								</TableRow>
						  )}
				</TableBody>
				{!loading && (
					<TableFooter sx={{ backgroundColor: '#E5E5E5', zIndex: 2, position: 'sticky', left: 0, bottom: 0 }}>
						{totalRow &&
							[totalRow].map((row: any, index: number) => (
								<TableRow key={`footer-${index.toString()}`}>
									{!hideLabel && (
										<TotalReportTableCell
											sx={{
												textAlign: 'left'
											}}
										>
											{`${t('total')} ${labelName}`}
										</TotalReportTableCell>
									)}
									{columns.map((column, index) => {
										const value = row[column.id as keyof typeof row];
										return (
											index !== 0 && (
												<TotalReportTableCell
													key={column.id}
													sx={{
														minWidth: column.minWidth,
														...column.style
													}}
													align={column.align}
												>
													<span>{formatColumnValue(column.type, value)}</span>
												</TotalReportTableCell>
											)
										);
									})}
								</TableRow>
							))}
					</TableFooter>
				)}
			</Table>
		</TableContainer>
	);
}
