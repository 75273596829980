import { styled } from '@mui/material';

export const ReportTableCell = styled('td')({
	padding: '6px 16px !important',
	fontSize: '12px',
	textAlign: 'right',
	borderBottom: '1px solid rgba(224, 224, 224, 1)',
	fontWeight: 400,
	minWidth: '144px',
	maxWidth: '144px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
});

export const TotalReportTableCell = styled('td')({
	padding: '6px 16px !important',
	fontSize: '12px',
	textAlign: 'right',
	borderBottom: '1px solid rgba(224, 224, 224, 1)',
	fontWeight: 600,
	minWidth: '144px',
	maxWidth: '144px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
});
