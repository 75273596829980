export const ACCESS_TAGS = {
	GET_ALL_USERS: 'GET_ALL_USERS',
	GET_ALL_CLIENT_ACCOUNT: 'GET_ALL_CLIENT_ACCOUNT',
	GET_GLOBAL_QUERY: 'GET_GLOBAL_QUERY',
	UPDATE_CLIENT_ACCOUNT: 'UPDATE_CLIENT_ACCOUNT',
	UPDATE_GLOBAL_QUERY: 'UPDATE_GLOBAL_QUERY',
	CREATE_GLOBAL_QUERY: 'CREATE_GLOBAL_QUERY',
	DELETE_GLOBAL_QUERY: 'DELETE_GLOBAL_QUERY',
	GET_ALL_CHANNELS: 'GET_ALL_CHANNELS',
	CREATE_CHANNEL: 'CREATE_CHANNEL',
	UPDATE_CHANNEL: 'UPDATE_CHANNEL',
	GET_CHANNEL: 'GET_CHANNEL',
	GET_ALL_CHANNELS_BY_PAGE: 'GET_ALL_CHANNELS_BY_PAGE',
	DELETE_CHANNEL: 'DELETE_CHANNEL',
	CHANNEL_DETAIL_EXPORT: 'CHANNEL_DETAIL_EXPORT',
	GET_CUSTOM_QUERY: 'GET_CUSTOM_QUERY',
	CREATE_CUSTOM_QUERY: 'CREATE_CUSTOM_QUERY',
	UPDATE_CUSTOM_QUERY: 'UPDATE_CUSTOM_QUERY',
	DELETE_CUSTOM_QUERY: 'DELETE_CUSTOM_QUERY',
	GET_SINGLE_ANALYSIS_REPORT: 'GET_SINGLE_ANALYSIS_REPORT',
	GET_SINGLE_ANALYSIS_LABEL_REPORT: 'GET_SINGLE_ANALYSIS_LABEL_REPORT',
	GET_DELTA_ANALYSIS_REPORT: 'GET_DELTA_ANALYSIS_REPORT',
	GET_DELTA_ANALYSIS_LABEL_REPORT: 'GET_DELTA_ANALYSIS_LABEL_REPORT',
	GET_CP_ATTRIBUTE_SEARCH_DATA: 'GET_CP_ATTRIBUTE_SEARCH_DATA',
	DAILY_FUNNEL_REPORT: 'DAILY_FUNNEL_REPORT',
	DAILY_CHANNEL_REPORT: 'DAILY_CHANNEL_REPORT'
};

export default ACCESS_TAGS;
